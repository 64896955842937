import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {ControllerParams} from 'yoshi-flow-editor-runtime';
import {BIService} from '../services/BIService';
import {CartService} from './CartService';
import {ORIGIN} from '../../components/cart/constants';
import {CheckoutNavigationApi} from '@wix/wixstores-client-storefront-sdk/dist/es/src/apis/CheckoutNavigationApi/CheckoutNavigationApi';
import {StartFastFlowUrlResponse} from '@wix/wixstores-client-storefront-sdk/dist/es/src/apis/CheckoutNavigationApi/constants';
import {ICartControllerApi} from '../../types/app.types';
import {ModalManagerService} from './ModalManagerService';

export interface FastFlowCheckoutInfo {
  cartId: string;
  cashierPaymentId: string;
  isPickupFlow: boolean;
  a11y: boolean;
  locale: string;
  deviceType: string;
  withTax: boolean;
  withShipping: boolean;
  shouldOpenCheckoutInViewer: boolean;
  originType: string;
}

export class NavigationService {
  private readonly controllerApi: ICartControllerApi;
  public get isNavigationToCheckoutInProcess(): boolean {
    return this._isNavigationToCheckoutInProcess;
  }

  public set isNavigationToCheckoutInProcess(value: boolean) {
    this._isNavigationToCheckoutInProcess = value;
    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.controllerApi.updateComponent();
  }
  private readonly siteStore: SiteStore;
  private readonly biService: BIService;
  private readonly publicData: ControllerParams['controllerConfig']['config']['publicData'];
  private readonly cartService: CartService;
  public readonly modalManagerService: ModalManagerService;
  private readonly checkoutNavigationApi: CheckoutNavigationApi;
  private _isNavigationToCheckoutInProcess: boolean = false;

  constructor({
    siteStore,
    biService,
    cartService,
    publicData,
    controllerApi,
    modalManagerService,
  }: {
    siteStore: SiteStore;
    biService: BIService;
    cartService: CartService;
    modalManagerService: ModalManagerService;
    publicData: ControllerParams['controllerConfig']['config']['publicData'];
    controllerApi: ICartControllerApi;
  }) {
    this.siteStore = siteStore;
    this.cartService = cartService;
    this.biService = biService;
    this.publicData = publicData;
    this.controllerApi = controllerApi;
    this.modalManagerService = modalManagerService;
    this.checkoutNavigationApi = new CheckoutNavigationApi({siteStore: this.siteStore, origin: ORIGIN});
  }

  private get continueShoppingLinkObject() {
    return this.publicData.COMPONENT.CART_CONTINUE_SHOPPING_LINK_OBJECT;
  }

  public readonly continueShopping = async () => {
    this.biService.cartClickOnContinueShoppingSf(this.cartService.cart);
    this.siteStore.navigateToLink(await this.getLink());
  };

  private getLink() {
    return this.continueShoppingLinkObject
      ? this.siteStore.getLink(this.continueShoppingLinkObject)
      : this.siteStore.getHomepageLink();
  }

  /* istanbul ignore next */
  public async getContinueShoppingHref() {
    try {
      return (await this.getLink()).url;
    } catch {
      //
    }
    return '#';
  }

  public readonly navigateToProduct = (urlPart: string) => {
    const to = {sectionId: PageMap.PRODUCT, state: urlPart};
    return this.siteStore.navigate(to, true);
  };

  public async startFastFlowCheckout({
    cartId,
    cashierPaymentId,
    isPickupFlow,
    a11y,
    locale,
    deviceType,
    withTax,
    withShipping,
    shouldOpenCheckoutInViewer,
    originType,
  }: FastFlowCheckoutInfo): Promise<{
    wasServerResponseSuccessful: boolean;
    startFastFlowUrlResponse?: StartFastFlowUrlResponse;
  }> {
    const queryParams = {
      paymentId: cashierPaymentId,
      isPickupFlow,
      a11y,
      forceLocale: locale,
      deviceType,
      withTax,
      withShipping,
      inUserDomain: shouldOpenCheckoutInViewer,
      origin: ORIGIN,
      originType,
    };

    try {
      return {
        wasServerResponseSuccessful: true,
        startFastFlowUrlResponse: await this.checkoutNavigationApi.startFastFlowUrl(cartId, queryParams),
      };
    } catch (e) {
      await this.modalManagerService.modalManger.openStartFastFlowError();
      return {wasServerResponseSuccessful: false};
    }
  }
}
